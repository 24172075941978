import React from 'react';
import { Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { languageEnum } from 'configuration/specs';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import WarningIcon from 'assets/WarningIcon.jsx';

const languages = Object.keys(languageEnum);
const userLanguage = (navigator.language || navigator.userLanguage || 'en').toLowerCase().substring(0, 2)

const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: theme.palette.primary.contrast,
    zIndex: 1,
    padding: '0px 4px !important',
    color: props => props.hasErrors
      ? theme.palette.error.main + '!important'
      : theme.palette.primary.main + '!important',
  },
  select: {
    border: '2px solid !important',
    borderColor: props => props.hasErrors
      ? theme.palette.error.main + '!important'
      : theme.palette.primary.main + '!important',
    '& fieldset': { border: 'none' },
  },
  selectIcon: { color: theme.palette.primary.main + '!important' },
  divider: { backgroundColor: theme.palette.primary.dark },
  required: {
    paddingLeft: 3,
    fontSize: 21,
  },
  caption: {
    margin: '-3px 14px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))


const TOP_LANGUAGES = [
  'en',
  'es',
  'fr'
]


export default function LanguageSelector({ f, ind, onChange }) {
  const hasErrors = !!f.error
  const classes = useStyles({ hasErrors })
  const { t } = useTranslation()

  return (<>
    <FormControl fullWidth>
      <InputLabel shrink className={classes.label} htmlFor='language-selector'>
        {t("language")}{!!f.required ? <span className={classes.required}>{'*'}</span> : ''}
      </InputLabel>
      <Select
        displayEmpty
        value={f.value ?? ''}
        variant='outlined'
        id='language-selector'
        className={classes.select}
        classes={{ icon: classes.selectIcon }}
        inputProps={{ autoFocus: true }}
        onChange={e => onChange(ind, e.target.value)}
      >
        <MenuItem disabled value={''}>{t("select_language")}</MenuItem>
        <Divider component="li" className={classes.divider} variant="middle" />
        {!TOP_LANGUAGES.includes(userLanguage) && <MenuItem value={userLanguage}>{languageEnum[userLanguage]}</MenuItem>}
        {TOP_LANGUAGES.map(l =>
          <MenuItem key={l} value={l}>{languageEnum[l]}</MenuItem>
        )}
        <Divider component="li" className={classes.divider} variant="middle" />
        {languages.map(l => {
          if (l === userLanguage || TOP_LANGUAGES.includes(l)) { return null }
          return <MenuItem key={l} value={l}>{languageEnum[l]}</MenuItem>
        })}
      </Select>
      {hasErrors && (<div className={classes.caption}>
        <WarningIcon color='error' style={{ marginTop: 8 }} />
        <Typography variant='caption' color={'error'}>{`${t("language")} is required`}</Typography>
      </div>)}
    </FormControl>
  </>);
};
