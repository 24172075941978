import axios from 'axios';
import { ImageDataToBlob } from 'helpers.js';


const API_URl = process.env.REACT_APP_API_URL;


export const loadData = async (auth, listing_id = null, booking_id = null) => {
   try {
      let params = { params: { auth: auth, listing_id: listing_id, booking_id: booking_id } }
      const response = await axios.get(API_URl + '/keycard', params)
      return response;
   } catch (err) { throw err; }
}


function dataURItoBlob(dataURI) {
   console.log('1: ', dataURI);
   var binary = atob(dataURI.split(',')[1]);
   var array = [];
   console.log('2: ', binary.length);
   for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
   }
   return new Blob([new Uint8Array(array)], {
      type: 'application/pdf'
   });
}


export const uploadImage = async (image) => {
   var options = {
      headers: {
         'Content-Type': 'image/jpeg'
      }
   };
   try {
      const response = await axios.get(API_URl + '/keycard/verify/url')
      console.log("GOT URL", response)
      const picture_blob = dataURItoBlob(image);
      const picture = await axios.put(response.data.presigned_url, picture_blob, options)
      console.log("GOT PICTURE", picture)
      return response.data.url
   } catch (err) { console.log("ERROR UPLOADING IMAGE", err); throw err; }
}


export const handleUpload = async (auth, scanResults, image) => {
   let response = await axios.get(API_URl + '/keycard/verify/url', { params: { auth: auth } })
   //let s3_url = response.data
   var options = {
      headers: {
         'Content-Type': 'image/jpeg'
      }
   };
   let blob = ImageDataToBlob(image)
   let picture = await axios.put(response.data.presigned_url, blob, options)
   return picture
}


export const unlockPost = async (auth, selectedBooking, device_id, action) => {
   try {
      let body = {
         auth,
         booking_id: selectedBooking,
         device_id,
         action
      }
      const response = await axios.post(API_URl + '/keycard/unlock', body);
      return response;
   } catch (err) { throw err; }
}


export const purchasePost = async body => {
   try {
      const response = await axios.post(API_URl + '/keycard/purchase', body);
      return response;
   } catch (err) { throw err; }
}


export const purchaseGet = async (params) => {
   try {
      const response = await axios.get(API_URl + `/keycard/purchase${params}`);
      return response;
   } catch (err) { throw err; }
}


export const checkinPost = async (auth, selectedBooking) => {
   let body = {
      booking_id: selectedBooking,
      auth: auth,
      status: 'checked_in'
   }
   let components = await axios.post(API_URl + '/keycard/status', body)
   return { ...components, view: 'checked_in' }
}

export const checkoutPost = async (auth, selectedBooking) => {
   let body = {
      booking_id: selectedBooking,
      auth: auth,
      status: 'checked_out'
   }
   let components = await axios.post(API_URl + '/keycard/status', body)
   return { ...components, view: 'checked_out' }
}


export const verifyPost = async (auth, selectedBooking, listing_id, verify_args, view) => {
   let body = {
      booking_id: selectedBooking,
      auth: auth,
      listing_id: listing_id,
      data: {
         ...verify_args,
         verifier: view,
      }
   }
   let r = await axios.post(API_URl + '/keycard/verify', body)
   return r
}


export const feedbackPost = async (auth, selectedBooking, message, attachments) => {
   let body = {
      booking_id: selectedBooking,
      auth: auth,
      message: message,
      attachments: attachments
   }
   let r = await axios.post(API_URl + '/keycard/feedback', body)
   return r
}

export const downloadAppleWallet = async (auth, booking_id = null) => {
   try {
      let params = { params: { auth: auth, booking_id: booking_id } }
      const response = await axios.get(API_URl + '/wallet', params, {
         responseType: 'application/vnd.apple.pkpass'
      })
      return response;
   } catch (err) { throw err; }
}