import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AppsIcon from '@mui/icons-material/Apps';
import PetsIcon from '@mui/icons-material/Pets';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarIcon from 'assets/CalendarIcon.jsx';
import CheckInIcon from 'assets/CheckInIcon.jsx';
import CheckOutIcon from 'assets/CheckOutIcon.jsx';
import EventIcon from '@mui/icons-material/Event';
import { makeStyles, useTheme } from '@mui/styles';
import { hexToRgb } from 'helpers.js';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
	box: {
		marginBottom: 15,
		borderRadius: '10px'
	}
});


const VerifyItem = ({
	verified, component_type, onClick, disabled = false, margin = 0,
	name = null, components, error = false, setView
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	let spec = {
		fee: {
			icon: <AttachMoneyIcon sx={{ color: 'primary.main' }} />,
			id: 'fee',
			disabled: !!error
		},
		pet_fee: {
			icon: <PetsIcon sx={{ color: 'primary.main' }} />,
			id: 'pet_fee',
			disabled: !!error
		},
		custom_fee: {
			icon: <AttachMoneyIcon sx={{ color: 'primary.main' }} />,
			id: 'custom_fee',
			disabled: !!error
		},
		deposit: {
			icon: <AccountBalanceIcon sx={{ color: 'primary.main' }} />,
			id: 'deposit',
			disabled: !!error
		},
		id_scan: {
			name: t("id_check"),
			icon: <ContactMailIcon sx={{ color: 'primary.main' }} />,
			id: "idCheck"
		},
		id_upload: {
			name: t("id_check") + (!!!components?.id_upload ? " - Failed, please contact host" : ""),
			icon: <ContactMailIcon sx={{ color: 'primary.main' }} />,
			id: "idCheck",
			disabled: !!!components?.id_upload
		},
		agreement: {
			name: t("agreement"),
			icon: <TextSnippetIcon sx={{ color: 'primary.main' }} />,
			id: "agreements"
		},
		details: {
			name: t("your_details"),
			icon: <AccountCircleIcon sx={{ color: 'primary.main' }} />,
			id: "details"
		},
		govt_id_front: {
			name: t("front_id"),
			icon: <ContactMailIcon sx={{ color: 'primary.main' }} />,
			id: "idFront"
		},
		govt_id_back: {
			name: t("back_id"),
			icon: <ContactMailIcon sx={{ color: 'primary.main' }} />,
			id: "idBack"
		},
		selfie: {
			name: t("selfie_word"),
			icon: <AccountCircleIcon sx={{ color: 'primary.main' }} />,
			id: "selfie"
		},
		upload_photo: {
			name: t("upload_photo"),
			icon: <PhotoLibraryIcon sx={{ color: 'primary.main' }} />
		},
		check_in_steps: {
			name: t("checkin"),
			icon: <CheckInIcon sx={{ color: 'primary.main' }} />
		},
		add_to_home: {
			name: t("add_to_home"),
			icon: <AppsIcon sx={{ color: 'primary.main' }} />
		},
		add_to_cal: {
			name: t("add_to_calendar"),
			icon: <EventIcon sx={{ color: 'primary.main' }} />

		}
	}[component_type]

	return (<ListItem
		key={component_type}
		style={{ border: `1.5px solid ${hexToRgb(theme.palette.primary.main)}`, margin: 0, marginBottom: margin }}
		className={classes.box} disablePadding
		id={`${spec.id}${verified ? '-disabled' : ''}`}
	>
		<ListItemButton disabled={disabled || spec?.disabled || verified} onClick={e => !!onClick ? onClick(e) : setView(e)}>
			<ListItemIcon style={{ minWidth: 0, marginRight: 5 }}>
				{spec.icon}
			</ListItemIcon>
			<ListItemText
				primaryTypographyProps={{ variant: 'h5' }}
				primary={spec.name || name}
				style={{ padding: 10 }}
			/>
			{verified ? <CheckCircleIcon sx={{ color: 'primary.main' }} /> : <KeyboardArrowRightIcon sx={{ color: 'primary.main' }} />}
		</ListItemButton>
	</ListItem>)
}

export default VerifyItem

