import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isIOS, isMacOs } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { AppContext, allowedViewsToShowInfo } from 'App'
import { loadData } from 'api'
import i18next from 'i18next'
import getTheme from 'theme'
// UI
import { makeStyles } from '@mui/styles'
import { Box, ButtonBase, IconButton, Typography } from '@mui/material'
import { AppleWalletIcon } from 'assets/Icons.jsx'
import { ConciergeIcon } from 'assets/BrandIcons'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
// Custom
import LoadingScreen from 'components/Loading'
import SectionHeaderCard from 'components/Cards/SectionHeaderCard'
import BrandingFooter from 'components/BrandingFooter'
import { getAppleWallet } from 'helpers';

const defaultBGImg = "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp"

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    maxWidth: 800,
  },
  img: {
    borderRadius: 10,
    objectFit: 'cover',
    width: '30%',
    maxHeight: 80,
    maxWidth: 80,
    marginRight: theme.spacing(1)
  },
  rowItem: {
    display: 'flex'
  },
  purchase_card: {
    cursor: 'pointer',
    borderRadius: 10,
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    maxWidth: 508,
    margin: props => !!props.disableGutters
      ? 0
      : theme.spacing(0, 2.5),
    '&.noImage': {
      paddingLeft: theme.spacing(1),
      maxWidth: 504,
    },
  },
  absoluteRow: {
    position: 'absolute',
    top: 0,
    left: 'auto',
    right: 'auto',
    zIndex: 1,
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2.5),
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(0.5),
  },
  closeBtn: {
    backgroundColor: `${theme.palette.primary.contrast + 'a6'} !important`,
    padding: theme.spacing(1.5) + '!important',
  },
  icon: {
    fontSize: '24px !important',
    color: theme.palette.primary.main,
  },
}))


const Purchases = ({
  setTheme, isLoading, setIsLoading
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { kc, view, setView, auth, setKc } = useContext(AppContext)
  const { listing_id } = useParams()
  let isListingBP = !location.search
  let components = kc?.components ?? {}
  let bookingId = kc?.booking_id
  let hasUpsells = !!components.upsells?.length
  let pendingActions = [...(components?.pending_upsells || []), ...(components?.requires_action_upsell || [])]
  let purchasedUpsells = components?.purchased_upsells || []

  function redirectToHome() {
    window.location.replace(location.pathname.replace("/upgrades", isListingBP ? "/" : "") + location.search)
  }

  useEffect(() => {
    if (!kc) {
      setIsLoading(prev => true)
      loadData(auth, listing_id, null).then(r => {
        setKc(r.data)
        const guest_language = r.data.components?.guest?.language
        if (!!guest_language) { i18next.changeLanguage(guest_language) }
        let metadata = r.data.components.metadata
        document.body.style.background = metadata.color2;
        document.querySelector('meta[name="theme-color"]').setAttribute('content', metadata.color2);
        window.title = `Boarding Pass - ${r.data.components.metadata.name}`
        setTheme(getTheme(metadata))
        setView(r.data.view, { ...r.data.components, view: r.data.view })
        setIsLoading(prev => false)
        if (!r.data?.components?.upsells?.length || !allowedViewsToShowInfo.includes(r.data.view)) { redirectToHome() }
      }).catch(e => {
        console.log("ERROR GETTING KEYCARD", e)
        setView('error')
        setIsLoading(prev => false)
      })
    } else if (!hasUpsells) {
      redirectToHome()
    }
  }, [])


  const pendingUpsells = !!pendingActions.length && <Box mt={5} mx={2.5} mb={0}>
    {pendingActions?.map((b, i) =>
      <div key={i} style={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: 20, padding: 10, display: 'flex', alignItems: 'center' }}>
        <ConciergeIcon sx={{ color: theme.palette.primary.main }} />
        <div style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
          <Typography>{b.message}</Typography>
        </div>
      </div>
    )}
  </Box>



  if (isLoading) { return <LoadingScreen /> }
  else if (!kc || !hasUpsells) { return null }
  return (<div className={classes.container}>
    <SectionHeaderCard
      title={t("purchases")}
      img={components.upsells?.[0]?.header_image ?? defaultBGImg}
    />
    <div className={classes.absoluteRow}>
      <Box className={classes.buttonRow}>
        <IconButton onClick={() => navigate(-1)} className={classes.closeBtn}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </Box>
    </div>
    <div className={classes.content}>
      {pendingUpsells}
      {/* Upsells */}
      <Box mt={!!pendingActions.length ? 0 : 5}>
        <Box py={1.5} px={2.5}>
          <Typography variant='body2'>{t("your_purchased_upgrades").toUpperCase()}</Typography>
        </Box>
        {purchasedUpsells.map(p =>
          <div className={classes.purchase_card}>
            <Box p={1} display="flex">
              {!!p.header_image && (
                <img src={p.header_image} className={classes.img} />
              )}
              <Typography>{p.bill.title}</Typography>
            </Box>
            <Box p={1}>
              <div className={classes.rowItem}>
                <Typography variant='body2' style={{ flex: 1 }}>Date</Typography>
                <Typography>{new Date(p.bill.created_at).toDateString()}</Typography>
              </div>
              <div className={classes.rowItem}>
                <Typography variant='body2' style={{ flex: 1 }}>Status</Typography>
                <Typography>{p.bill.status.toUpperCase()}</Typography>
              </div>
              <div className={classes.rowItem}>
                <Typography variant='body2' style={{ flex: 1 }}>Total Paid</Typography>
                <Typography>{p.bill.total} {p.bill.currency}</Typography>
              </div>
            </Box>
          </div>
        )}
      </Box>
    </div>
    {/* Branding footer */}
    <Box mt={7.5} display='flex' flexDirection='column' alignItems='center'>
      <BrandingFooter
        metadata={components?.metadata}
        extras={
          view !== 'revoked' && (isMacOs || isIOS) ?
            <ButtonBase
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => getAppleWallet(auth, bookingId, setIsLoading)}
            >
              <AppleWalletIcon />
            </ButtonBase>
            :
            null
        }
      />
    </Box>
  </div>)
}

export default Purchases
