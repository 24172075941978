import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Link, Box, Modal } from '@mui/material';
// components
import BrandingFooter from 'components/BrandingFooter.jsx';
import VerifyItem from 'components/VerifyItem.jsx';
import { makeStyles } from '@mui/styles';
import StripePage from './StripePage';
import ProductPage from './ProductPage';
import { AppContext } from 'App';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}))


export default function VerifyPage({ setView }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { kc } = useContext(AppContext)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [productLink, setProductLink] = useState(null)
  const [stripeAccount, setStripeAccount] = useState(null)
  let components = kc?.components || {}


  function handlePurchaseRedirect(product) {
    setSelectedProduct(product)
  }

  const handleCloseProduct = (replace) => {
    setSelectedProduct(prev => null)
  }

  return <main>
    <div className={classes.container}>
      {(!!selectedProduct || !!productLink) && (
        <Modal open>
          <div>
            {!!productLink
              ? <StripePage
                link={productLink}
                stripeAccount={stripeAccount}
                onClose={() => setProductLink(prev => null)}
              />
              : <ProductPage
                product_id={selectedProduct.product_id}
                setStripeAccount={setStripeAccount}
                onPaymentRedirect={(link) => setProductLink(prev => link)}
                onClose={handleCloseProduct}
              />
            }
          </div>
        </Modal>
      )}
      <Box mt={5}>
        <Typography variant='h3'>{t("booking_verified")}</Typography>
      </Box>
      {Object.entries(components?.completed_verification || [])?.map(v =>
        <div>
          <VerifyItem key={v[0]} setView={() => setView(v[0])} component_type={v[0]} verified={v[1]} components={components} />
        </div>
      )}
      {(components?.fees || []).map(f =>
        <div>
          <VerifyItem
            onClick={() => handlePurchaseRedirect(f)}
            component_type={['deposit', 'pet_fee'].includes(f.product_id) ? f.product_id : 'custom_fee'}
            verified={f?.purchased}
            name={!!f?.error ? "Failed to Load Fee - Please Contact Host" : `${f?.name}${(f?.status == "requires_action") ? t("3ds_required") : ''}`}
            error={f?.error}
          />
        </div>
      )}
      <Typography variant="body2">{t("verify_disclaimer")}{' '}
        <Link variant="body2" href="https://ensoconnect.com/privacy-policy/" target="_blank">{t("terms_policy")}.</Link>
      </Typography>
    </div>
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' mt={5}>
      <BrandingFooter metadata={components?.metadata} />
    </Box>
  </main>
}
